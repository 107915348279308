/* eslint-disable no-return-assign */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import autobind from 'autobind-decorator';
import Dropzone from 'react-dropzone';
import { isUndefined } from 'lodash';
import InsideProjectBar from '../InsideProjectBar/InsideProjectBar';
import { DocumentsList } from '../../containers/DocumentsList';
import InsideProjectActionsList from './components/InsideProjectActionsList';
import { TeamManagement } from '../../containers/TeamManagement';
import { ActivityFeed } from '../../containers/ActivityFeed';
import GroupColor from '../Helpers/GroupColor';
import { isDesignLimitReached } from '../../../utils/designLimit';
import { preventDefaultEvent } from '../../../utils/common';
import { isItemIncluded } from '../../../controllers/actions/settings.actions';
import { UnstarredPrototypesEmptyPage, StarredPrototypesEmptyPage } from '../EmptyPage/EmptyPage';
import { starFilteringTypes } from '../../../constants/settings.constants';
import { getMixpanel } from '../../../../../shared/mixpanel';

export default class InsideProject extends Dropzone {
  getGroupColor() {
    const { project } = this.props;
    return project.groupColor ? `#${project.groupColor}` : '';
  }

  setLoadingClass(props) {
    const { isLoading } = props;
    if (isLoading) {
      document.body.classList.add('loading');
    } else {
      document.body.classList.remove('loading');
    }
  }

  componentWillMount() {
    this.setLoadingClass(this.props);
    this.isViewHidden = true;

    getMixpanel(async (mixpanel) => {
      mixpanel.track('project_loaded');
    });
  }

  componentWillUnmount() {
    document.body.classList.remove('loading');
    document.title = 'Dashboard';
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.isLoading !== nextProps.isLoading) {
      this.setLoadingClass(nextProps);
    }

    if (!isUndefined(this.props.project.name)) {
      document.title = `${this.props.project.name} - UXPin`;
    }
  }

  @autobind
  triggerDesignLimitsModal(e) {
    preventDefaultEvent(e);
    this.props.showAddPrototypesLimitsModal();
  }

  renderPrototypeList() {
    const {
      isMessageSendingError,
      currentUserData,
      project,
      channels,
      slackState,
      selectedChannel,
      sendMessageToChannel,
      isMessageSending,
      createDocument,
      createDocumentRaw,
      isDocumentAdding,
      currentAddedDocument,
      isDocumentUploadEnabled,
      isAccountParked,
      hasAccessToEditor,
      documents,
      filterType,
      favsPrototypes,
    } = this.props;

    const prototypesIds = getAllPrototypesIds(documents);

    const filteredPrototypes = prototypesIds.filter(
      (prototypeId) => isItemIncluded(prototypeId, favsPrototypes, filterType)
    );

    if (filteredPrototypes.length === 0 && prototypesIds.length !== 0) {
      if (filterType === starFilteringTypes.STARRED) {
        return <StarredPrototypesEmptyPage />;
      }

      return <UnstarredPrototypesEmptyPage />;
    }

    return (
      <DocumentsList
        project={project}
        parent={this.refs.insideProject}
        openBrowserFilePicker={this.onClick}
        currentUserData={currentUserData}
        channels={channels}
        slackState={slackState}
        selectedChannel={selectedChannel}
        createDocument={createDocument}
        createDocumentRaw={createDocumentRaw}
        isDocumentAdding={isDocumentAdding}
        hasAccessToEditor={hasAccessToEditor}
        currentAddedDocument={currentAddedDocument}
        isMessageSending={isMessageSending}
        isMessageSendingError={isMessageSendingError}
        sendMessageToChannel={sendMessageToChannel}
        isAccountParked={isAccountParked}
        isDocumentUploadEnabled={isDocumentUploadEnabled}
      />
    );
  }

  renderView() {
    const {
      project,
      projectsList,
      isLoading,
      currentStatus,
      currentStage,
      createDocument,
      createDocumentRaw,
      isDocumentAdding,
      canAddEditDeleteProjectStatus,
      currentAddedDocument,
      isDocumentUploadEnabled,
      isAccountParked,
      documentsCount,
      isProjectStatusEnabled,
      hasAccessToEditor,
      isUXDocumentsEnabled,
      totalDocumentsCount,
      isTrial,
      isPrototypeNameDisabled,
      isMergeLimitedLibrariesEnabled,
      isMergeDisabled,
      openModalboxAddMergeLibrary,
    } = this.props;
    if (!isLoading && !this.isViewHidden) {
      return (
        <div>
          <InsideProjectBar
            project={project}
            projectsList={projectsList}
            documentsCount={documentsCount}
            currentStatus={currentStatus}
            canAddEditDeleteProjectStatus={canAddEditDeleteProjectStatus}
            currentStage={currentStage}
            openProjectSettings={this.props.openProjectSettings}
            isProjectStatusEnabled={isProjectStatusEnabled}
          />
          <TeamManagement
            project={project}
          />
          <InsideProjectActionsList
            documentsCount={documentsCount}
            project={project}
            createDocument={createDocument}
            createDocumentRaw={createDocumentRaw}
            isDocumentAdding={isDocumentAdding}
            isPrototypeNameDisabled={isPrototypeNameDisabled}
            isMergeLimitedLibrariesEnabled={isMergeLimitedLibrariesEnabled}
            isMergeDisabled={isMergeDisabled}
            currentAddedDocument={currentAddedDocument}
            hasAccessToEditor={hasAccessToEditor}
            openBrowserFilePicker={this.onClick}
            openModalboxAddMergeLibrary={openModalboxAddMergeLibrary}
            isAccountParked={isAccountParked}
            isDocumentUploadEnabled={isDocumentUploadEnabled}
            isUXDocumentsEnabled={isUXDocumentsEnabled}
            totalDocumentsCount={totalDocumentsCount}
            showAddPrototypesLimitsModal={this.triggerDesignLimitsModal}
            isTrial={isTrial}
          />
          {this.renderPrototypeList()}
          <ActivityFeed idProject={project.idProject} />
        </div>
      );
    }

    this.isViewHidden = false;
  }

  onDragEnter(e) {
    e.preventDefault();
    if (this.isUploadDisabled()) { return; }

    // eslint-disable-next-line no-plusplus
    ++this.enterCounter;
    const dataTransferItems = e.dataTransfer && e.dataTransfer.items ? e.dataTransfer.items : [];
    const dataTransferTypes = e.dataTransfer && e.dataTransfer.types ? e.dataTransfer.types : [];
    const allFilesAccepted = this.allFilesAccepted(Array.prototype.slice.call(dataTransferItems));

    this.setState({
      isDragActive: allFilesAccepted && Array.prototype.indexOf.call(dataTransferTypes, 'Files') !== -1,
      isDragReject: !allFilesAccepted && Array.prototype.indexOf.call(dataTransferTypes, 'Files') !== -1,
    });

    if (this.props.onDragEnter) {
      this.props.onDragEnter.call(this, e);
    }
  }

  onClick(e) {
    if (this.isUploadDisabled()) { return; }

    super.onClick(e);
  }

  onDragLeave(e) {
    if (this.isUploadDisabled()) { return; }
    if (e.target.classList.contains('dragging-overlay')) {
      this.setState({
        isDragActive: false,
        isDragReject: false,
      });
    }

    super.onDragLeave(e);
  }

  getInsideProjectClasses() {
    const { insideProjectViewMode } = this.props;
    const classnames = [];

    if (this.state.isDragActive || this.state.isDragReject) {
      classnames.push('dragging-file');
    }

    if (this.state.isDragReject) {
      classnames.push('extension-error');
    }

    if (insideProjectViewMode) {
      classnames.push(`view-mode-${insideProjectViewMode}`);
    }

    return classnames.join(' ');
  }

  onDragOver(e) {
    if (this.isUploadDisabled()) { return; }

    const dataTransferTypes = e.dataTransfer && e.dataTransfer.types ? e.dataTransfer.types : [];
    if (Array.prototype.indexOf.call(dataTransferTypes, 'Files') !== -1) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }

  @autobind
  onFileDrop(e) {
    if (this.props.isFreePlan && this.isDesignLimitReached()) {
      this.triggerDesignLimitsModal();
    } else {
      this.onDrop(e);
    }

    this.setState({
      isDragActive: false,
      isDragReject: false,
    });
  }

  render() {
    const { progress } = this.props;

    return (
      <section
        className={this.getInsideProjectClasses()}
        ref="insideProject"
        id="inside-project"
        onDragEnter={this.onDragEnter}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onFileDrop}>
        <GroupColor color={this.getGroupColor()} progress={progress} />
        {this.renderView()}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={(el) => this.fileInputEl = el}
          multiple
          onChange={this.onFileDrop}
        />
      </section>
    );
  }

  isUploadDisabled() {
    return !this.props.isDocumentUploadEnabled || this.props.isAccountParked;
  }

  isDesignLimitReached() {
    const { totalDocumentsCount } = this.props;
    return totalDocumentsCount && isDesignLimitReached(totalDocumentsCount.in_plan, totalDocumentsCount.used);
  }
}

InsideProject.defaultProps = {
  isLoading: true,
  multiple: true,
};

InsideProject.propTypes = {
  accept: PropTypes.string.isRequired,
  minSize: PropTypes.number.isRequired,
  maxSize: PropTypes.number.isRequired,
  disablePreview: PropTypes.bool.isRequired,
  onDropAccepted: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
  isMessageSendingError: PropTypes.bool.isRequired,
  currentUserData: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  projectsList: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  channels: PropTypes.array.isRequired,
  slackState: PropTypes.object.isRequired,
  selectedChannel: PropTypes.string.isRequired,
  sendMessageToChannel: PropTypes.func.isRequired,
  isMessageSending: PropTypes.bool.isRequired,
  currentStatus: PropTypes.object.isRequired,
  currentStage: PropTypes.object.isRequired,
  createDocument: PropTypes.func.isRequired,
  isDocumentAdding: PropTypes.bool.isRequired,
  currentAddedDocument: PropTypes.object,
  insideProjectViewMode: PropTypes.string,
  isFreePlan: PropTypes.bool.isRequired,
};

function getAllPrototypesIds(documents) {
  return documents.map((document) => document.idDocument);
}
