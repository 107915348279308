import { connect } from 'react-redux';
import ModalboxDocumentChangeNameComponent from '../components/Modalboxes/ModalboxDocumentChangeName';
import {limitsContentTypes, modalsIds} from '../../constants/modal.constants';
import * as modal from '../../controllers/actions/modal.actions';
import * as documents from '../../controllers/actions/document.actions';
import {createWithRenameDocument} from "../../controllers/actions/insideProject.actions";

export const mapStateToProps = (state) => {
  const { data: modalData, openedModalsIds } = state.modal;
  const { project: { idProject }, totalDocumentsCount } = state.insideProject;


  return {
    idProject,
    totalDocumentsCount,
    isOpen: openedModalsIds[modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME] || false,
    data: modalData[modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME] || {},
  };
};

export const mapDispatchToProps = (dispatch) => ({
  renameDocument: (idDocument, name) => {
    dispatch(documents.renameDocument(idDocument, name));
  },

  createWithRenameDocument: (idProject, name, onFinish) => {
    return dispatch(createWithRenameDocument(idProject, name, onFinish));
  },

  showAddPrototypesLimitsModal: () => {
    dispatch(modal.openModal(modalsIds.LIMITS_EXCEEDED, {
      forcedContentType: limitsContentTypes.PROTOTYPE_ADD_LIMIT,
    }));
  },

  updateDocumentName: (idDocument, name) => {
    dispatch(documents.updateDocumentName(idDocument, name));
  },

  onClose: () => {
    dispatch(modal.closeModal(modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalboxDocumentChangeNameComponent);
