/* eslint-disable max-len */

'use strict';

import React from 'react';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { isDesktopApp, GLOBAL_ELEMENTS, Cloud } from '@uxpin/shared-components';

import InsideProjectActions from '../../Helpers/InsideProjectActions';
import DraggingArea from './DraggingArea';
import { isDesignLimitReached } from '../../../../utils/designLimit';
import { preventDefaultEvent } from '../../../../utils/common';
import { EVENT_CREATE_NEW_PROTOTYPE } from '../../../../constants/desktopAppEvents.constants';
import { getImagePath } from '../../../../utils/url';
import { LibrarySelectorCloud } from '../../Helpers/LibrarySelectorCloud';

@Cloud({ offset: {
  bottom: { x: 0, y: -20 },
} })
export default class InsideProjectEmptyComponent extends InsideProjectActions {
  constructor(props) {
    super(props);

    this.classes = {
      PROJECT_EMPTY: 'project-empty',
    };
  }

  componentWillMount() {
    document.body.classList.add(this.classes.PROJECT_EMPTY);
    if (isDesktopApp) {
      window.addEventListener(EVENT_CREATE_NEW_PROTOTYPE, this.createDocument);
    }
  }

  componentWillUnmount() {
    document.body.classList.remove(this.classes.PROJECT_EMPTY);
    if (isDesktopApp) {
      window.removeEventListener(EVENT_CREATE_NEW_PROTOTYPE, this.createDocument);
    }
  }

  getClasses() {
    return classnames('option-tile', {
      working: this.state.isWorking,
    });
  }

  getMergeClasses() {
    return classnames('option-tile', {
      working: this.state.isWorkingMerge,
    });
  }

  @autobind
  createDocument(e) {
    super.createDocument(e);
  }

  @autobind
  createMergeDocument(e, openLibraries) {
    const { createDocumentRaw, project } = this.props;

    this.setState({
      isWorking: true && !openLibraries,
      isWorkingMerge: !!openLibraries,
    });

    createDocumentRaw(project.idProject, null, openLibraries);
  }

  @autobind
  triggerDesignLimitsModal(e) {
    preventDefaultEvent(e);
    this.props.showAddPrototypesLimitsModal();
  }

  @autobind
  renderStartFromScratch() {
    const { totalDocumentsCount, isFreePlan } = this.props;
    const limitReached = isFreePlan && totalDocumentsCount && isDesignLimitReached(totalDocumentsCount.in_plan, totalDocumentsCount.used);
    const action = limitReached ? this.triggerDesignLimitsModal : this.createDocument;

    return (
      <li className="start-option start-from-scratch" onClick={action}>
        <div className={this.getClasses()}>
          <img
            src={getImagePath('start-from-scratch.svg')}
            className="tile-image working-hide"
            alt="Start from scratch"
            width="64"
            height="50"
          />
          <span className="working-hide">New prototype</span>
        </div>
      </li>
    );
  }

  @autobind
  renderDesignWithMerge() {
    const { trigger, totalDocumentsCount, isFreePlan } = this.props;
    const limitReached = isFreePlan && totalDocumentsCount && isDesignLimitReached(totalDocumentsCount.in_plan, totalDocumentsCount.used);
    const action = limitReached ? this.triggerDesignLimitsModal : this.createDocument;
    const props = limitReached ? { onClick: action } : trigger;

    return (
      <li {...props} className="start-option merge-start">
        <div className="start-option-title">Merge</div>
        <div className={this.getMergeClasses()}>
          <img
            src={getImagePath('merge-info/DesignWithMergeComponents.svg')}
            className="tile-image working-hide"
            alt="Design with coded components"
            height="46"
          />
          <span className="working-hide">Design with coded <br /> components</span>
        </div>
      </li>
    );
  }

  render() {
    const { cloud, isDocumentUploadEnabled, isMergeLimitedLibrariesEnabled, isMergeDisabled, openBrowserFilePicker, openModalboxAddMergeLibrary, isSketchUploadAllowed, isTrial } = this.props;
    // Add 'project-empty' class to <body> tag to hide aside sections
    return (
      <div id="inside-project-empty">
        <h1>What do you want to start with today?</h1>
        <span>Use our powerful design editor, or import your existing designs</span>

        <ul className={`options-list ${GLOBAL_ELEMENTS.UNORDERED_LIST}`}>
          {!isMergeDisabled ? this.renderDesignWithMerge() : null}
          {this.renderStartFromScratch()}
          <DraggingArea
            openBrowserFilePicker={openBrowserFilePicker}
            isDocumentUploadEnabled={isDocumentUploadEnabled}
            isSketchUploadAllowed={isSketchUploadAllowed}
          />
        </ul>
        <span className="empty-tip">Tip: You can also drag & drop your files.</span>
        {!isMergeDisabled && (
          <LibrarySelectorCloud
            cloud={cloud}
            isMergeLimitedLibrariesEnabled={isMergeLimitedLibrariesEnabled}
            createMergeDocument={this.createMergeDocument}
            openModalboxAddMergeLibrary={openModalboxAddMergeLibrary}
          />
        )}
      </div>
    );
  }
}
