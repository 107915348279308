'use strict';

import { insideProjectActions } from '../../constants/insideProject.constants';
import { projectsSortingTypes } from '../../constants/projects.constants';
import { projectStatusActions } from '../../constants/projectStatus.constants';
import { membersActions } from '../../constants/members.constants';
import routes from '../../constants/routes.constants';
import { refreshActivityFeed } from './activityFeed.actions';
import { updateSettings } from './settings.actions';
import { request } from '../../utils/request';
import { goTo, redirectToNotFoundView } from '../../utils/router';
import { getMixpanel } from '../../../../shared/mixpanel';

const requestFetchingProjectData = () => ({
  type: insideProjectActions.REQUEST_FETCHING_PROJECT_DATA,
});

const requestCreatingDocument = () => ({
  type: insideProjectActions.REQUEST_CREATING_DOCUMENT,
});

const updateProgress = (progress) => ({
  type: insideProjectActions.UPDATE_PROGRESS,
  progress,
});

const requestSavingProjectMembers = () => ({
  type: insideProjectActions.REQUEST_SAVING_PROJECT_MEMBERS,
});

const saveProjectMembers = () => ({
  type: insideProjectActions.SAVE_PROJECT_MEMBERS,
});

const updateProjectMembers = (data) => ({
  type: membersActions.FETCH_PROJECT_MEMBERS,
  data,
});

const handleProjectMembersError = () => ({
  type: insideProjectActions.PROJECT_MEMBERS_SAVING_ERROR,
});

export const createDocument = (idProject, template = null, openLibraries = false) => (dispatch) => {
  const body = { idProject };

  if (template) {
    body.template = template;
  }

  if (openLibraries) {
    body.openLibraries = openLibraries;
  }

  dispatch(requestCreatingDocument());

  getMixpanel((mixpanel) => {
    mixpanel.track('prototype_created', {
      document_created_type: body.template || 'Prototype',
      prototype_created_library_chosen: body.openLibraries || 'None',
    });
    mixpanel.people.increment('number_of_prototypes_created');
  });

  return request.post('/document/create', {
    body,
  }).then((data) => {
    dispatch({
      type: insideProjectActions.CREATE_DOCUMENT,
      data,
    });
  });
};

export const createWithRenameDocument = (idProject, name, onFinish) => (dispatch) => {
  const body = { idProject };

  dispatch(requestCreatingDocument());

  getMixpanel((mixpanel) => {
    mixpanel.track('prototype_created', {
      document_created_type: body.template || 'Prototype',
      prototype_created_library_chosen: body.openLibraries || 'None',
    });
    mixpanel.people.increment('number_of_prototypes_created');
  });

  return request.post('/document/create', {
    body,
  }).then((data) => {
    request.post(`/documents/${data.idDocument}/name`, {
      body: { name },
    }).then(() => {
      dispatch({
        type: insideProjectActions.CREATE_DOCUMENT,
        data,
      });

      if (onFinish && typeof onFinish === 'function') {
        onFinish();
      }
    })
  });
};

export const setSortType = (sortType) => (dispatch) => updateSettings('documentSortingType', sortType, false)(dispatch);

export const fetchDocumentsCount = () => (dispatch) => {
  request.get('/account/documentsCount')
    .then((data) => {
      dispatch({
        type: insideProjectActions.FETCH_DOCUMENTS_COUNT,
        data,
      });
    });
};

export const fetchProjectData = (idProject) => (dispatch) => {
  let insideProjectViewMode;

  dispatch(requestFetchingProjectData());

  request.get(`/project/${idProject}`)
    .then((data) => {
      dispatch({
        type: insideProjectActions.FETCH_PROJECT_DATA,
        project: data,
      });
      dispatch({
        type: projectStatusActions.FETCH_PROGRESS,
        progress: {
          stage: {
            idStage: data.stageId,
            name: data.stageName,
          },
          status: {
            idStatus: data.statusId,
            name: data.statusName,
          },
        },
      });

      dispatch(updateProgress(0.2));
    })
    .then(() => request.get('/projects/index'))
    .then((data) => {
      dispatch({
        type: insideProjectActions.FETCH_PROJECTS_LIST,
        projectsList: data,
      });
      dispatch(updateProgress(0.3));
    })
    .then(() => request.get(`/project/${idProject}/members`))
    .then((data) => {
      dispatch({
        type: membersActions.FETCH_PROJECT_MEMBERS,
        data,
      });
      dispatch(updateProgress(0.1));
    })
    .then(() => dispatch(fetchDocumentsCount()))
    .then(() => request.get('/settings'))
    .then((data) => {
      insideProjectViewMode = data.insideProjectViewMode || '';

      if (insideProjectViewMode) {
        dispatch({
          type: insideProjectActions.SET_VIEW_MODE,
          mode: insideProjectViewMode,
        });
      }

      dispatch({
        type: insideProjectActions.SET_SORT_TYPE,
        sortType: data.documentSortingType || projectsSortingTypes.CREATE_DATE_DESC,
      });

      dispatch(updateProgress(0.1));
    })
    .then(() => request.get(`/project/${idProject}/documents`))
    .then((documents) => {
      dispatch({
        type: insideProjectActions.FETCH_DOCUMENTS,
        documents,
      });

      dispatch({
        type: insideProjectActions.STOP_LOADER,
      });

      return documents.map((document) => document.hash);
    })
    .then((documentHashes) => request.post('/documents/comments/count', {
      body: documentHashes,
    }), (error) => {
      switch (error.status) {
        case 404:
          redirectToNotFoundView();
          break;
        case 403:
          goTo(routes.PROJECTS);
          break;
        default:

          // TODO HANDLE ERROR
          break;
      }
    })
    .then((commentsCount) => {
      dispatch({
        type: insideProjectActions.SET_DOCUMENTS_COMMENTS_COUNT,
        commentsCount,
      });
    }, () => {
      dispatch({
        type: insideProjectActions.SET_DOCUMENTS_COMMENTS_COUNT,
        commentsCount: {},
      });
    });
};

export const saveMembers = (idProject, userIds) => (dispatch) => {
  dispatch(requestSavingProjectMembers());

  request.put(`/project/${idProject}/members`, {
    body: {
      userIds,
    },
  })
    .then(() => {
      dispatch(saveProjectMembers());
      dispatch(updateProjectMembers(userIds));
      refreshActivityFeed(idProject)(dispatch);
    })
    .catch(() => {
      dispatch(handleProjectMembersError());
    });
};

export const setDocumentWorkingState = (idDocument) => (dispatch) => {
  dispatch({
    type: insideProjectActions.SET_DOCUMENT_WORKING_STATE,
    workingDocumentId: idDocument,
  });
};

export const setInsideProjectViewMode = (mode) => (dispatch) => {
  dispatch({
    type: insideProjectActions.SET_VIEW_MODE,
    mode,
  });
  request.put('/settings', {
    body: {
      insideProjectViewMode: mode,
    },
  })
    .catch(() => {
    // TODO HANDLE ERROR
    });
};
