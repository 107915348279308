/* eslint-disable max-len */

import { connect } from 'react-redux';
import * as slack from '../../controllers/actions/slack.actions';
import { projects } from '../../controllers/actions/projects.actions';
import { fetchProjectData, createDocument, fetchDocumentsCount } from '../../controllers/actions/insideProject.actions';
import { getActivityFeed, resetActivityFeedData } from '../../controllers/actions/activityFeed.actions';
import * as document from '../../controllers/actions/document.actions';
import * as settings from '../../controllers/actions/settings.actions';
import ProjectRoute from '../components/Layout/routes/ProjectRoute';
import * as modal from '../../controllers/actions/modal.actions';
import { openFeatureUpgrade } from '../../controllers/actions/pricing.actions';
import { modalsIds, limitsContentTypes } from '../../constants/modal.constants';
import { isAccountParked, isFreePlanType } from '../../utils/account';
import { isSketchDirectUploadAllowed } from '../../utils/uploadFiles';
import { FEATURES, FEATURE_STATUS } from '../../constants/features.constants';
import { isMergeDisabled } from '../../controllers/helpers/features.helper';

export const mapStateToProps = (state) => {
  const {
    project, projectsList, isLoading, progress, isDocumentAdding, currentAddedDocument, insideProjectViewMode, totalDocumentsCount,
  } = state.insideProject;
  const {
    slackState, channels, selectedChannel, isChannelsListLoading, isMessageSending, isMessageSendingError,
  } = state.slack;
  const { currentStage, currentStatus } = state.projectStatus;
  const { data } = state.currentUser;
  const { permissions: { can_add_edit_delete_project_status: canAddEditDeleteProjectStatus, has_access_to_editor: hasAccessToEditor } } = state.permissions;
  const {
    features: {
      documents_upload: documentsUpload,
      project_status: projectStatus,
      sketch_direct_upload: sketchDirectUpload,
      ux_documents: uxDocuments,
    },
    features,
  } = state.features;
  const { documents } = state.insideProject;
  const { items } = state.projects;
  const { groupsExpandedMode, groupsOrder, favsPrototypes } = state.settings.data;
  const { accountPlan } = state.accountSettings;
  const isSketchUploadAllowed = isSketchDirectUploadAllowed(sketchDirectUpload);
  const filterType = settings.getPrototypeFilterType(state);

  return {
    groupsExpandedMode,
    groupsOrder,
    project,
    isMergeDisabled: isMergeDisabled(features),
    isMergeLimitedLibrariesEnabled: features[FEATURES.MERGE_LIMITED_LIBRARIES] === FEATURE_STATUS.ENABLED,
    isPrototypeNameDisabled: features[FEATURES.PROTOTYPE_NAME] !== FEATURE_STATUS.ENABLED,
    isAccountParked: isAccountParked(data.idActiveAccount, data.accounts),
    isFreePlan: isFreePlanType(accountPlan),
    isTrial: accountPlan && accountPlan.is_trial,
    isDocumentUploadEnabled: documentsUpload === FEATURE_STATUS.ENABLED,
    isDocumentAdding,
    currentAddedDocument,
    projectsList,
    channels,
    documentsCount: documents.length,
    hasAccessToEditor: hasAccessToEditor && hasAccessToEditor.selected !== 'no',
    canAddEditDeleteProjectStatus: canAddEditDeleteProjectStatus && canAddEditDeleteProjectStatus.selected === 'yes',
    isLoading,
    slackState,
    selectedChannel,
    progress,
    isChannelsListLoading,
    isMessageSending,
    currentStage,
    currentStatus,
    isMessageSendingError,
    projectGroups: items,
    currentUserData: data,
    insideProjectViewMode,
    isProjectStatusEnabled: projectStatus === FEATURE_STATUS.ENABLED,
    isUXDocumentsEnabled: uxDocuments === FEATURE_STATUS.ENABLED,
    isSketchUploadAllowed,
    totalDocumentsCount,
    documents,
    filterType,
    favsPrototypes,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  getProjectData: (idProject) => {
    dispatch(fetchProjectData(idProject));
  },

  getActivityFeed: (idProject) => {
    dispatch(getActivityFeed(idProject));
  },

  resetActivityFeedData: () => {
    dispatch(resetActivityFeedData());
  },

  closeOpenedModals: () => {
    dispatch(modal.closeOpenedModals());
  },

  createDocument: (idProject, template = null, openLibraries = false) => {
    dispatch(createDocument(idProject, template, openLibraries));
  },

  openModalboxDocumentChangeName: (document) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME, document));
  },

  openModalboxAddMergeLibrary: () => {
    dispatch(modal.openModal(modalsIds.MODALBOX_ADD_MERGE_LIBRARY_BY_GIT_AND_NPM, { shouldCreateDocument: true }));
  },

  fetchProjects: (groupsOrder, groupsExpandedMode) => {
    dispatch(projects.fetchProjects(groupsOrder, groupsExpandedMode));
  },

  getSlackState: () => {
    dispatch(slack.getState());
  },

  sendMessageToChannel: (channel, message) => {
    dispatch(slack.sendMessageToChannel(channel, message));
  },

  getChannelsList: (idProject) => {
    dispatch(slack.getChannelsList(idProject));
  },

  createDocumentByUploadFile: (idDocument, files, withoutProgress) => {
    dispatch(document.createDocumentByUploadFile(idDocument, files, withoutProgress));
  },

  updateUploadDocumentProgress: (idDocument, progress) => {
    dispatch(document.updateUploadDocumentProgress(idDocument, progress));
  },

  createUploadedDocument: (idDocument, idProject, fileData, onError) => {
    dispatch(document.updateUploadDocumentData(idDocument, idProject, fileData, onError));
  },

  updateDocumentData: (idDocument, data) => {
    dispatch(document.updateData(idDocument, data));
  },

  setDocumentError: (idDocument, tryAgain, cancel) => {
    dispatch(document.setDocumentError(idDocument, tryAgain, cancel));
  },

  removeDocumentPermanently: (idDocument) => {
    dispatch(document.removeDocumentPermanently(idDocument));
  },

  openProjectSettings: (data) => {
    dispatch(modal.openModal(modalsIds.PROJECT_SETTINGS, data));
  },

  fetchDocumentsCount: () => {
    dispatch(fetchDocumentsCount());
  },

  showAddPrototypesLimitsModal: () => {
    dispatch(modal.openModal(modalsIds.LIMITS_EXCEEDED, {
      forcedContentType: limitsContentTypes.PROTOTYPE_ADD_LIMIT,
    }));
  },

  openFeatureUpgrade: () => {
    dispatch(openFeatureUpgrade(FEATURES.PROJECT_STATUS));
  },
});

export const InsideProject = connect(mapStateToProps, mapDispatchToProps)(ProjectRoute);
