/* eslint-disable max-len */

'use strict';

import React from 'react';
import classnames from 'classnames';
import autobind from 'autobind-decorator';
import {
  GLOBAL_ELEMENTS, isDesktopApp,
} from '@uxpin/shared-components';
import { Button } from '@uxpin/design-system';
import ActionDocuments from './ActionDocuments';
import InsideProjectActions from '../../Helpers/InsideProjectActions';
import { localStorage } from '../../../../constants/localStorage.constants';
import * as LocalStorage from '../../../../utils/localStorage';
import { isDesignLimitReached } from '../../../../utils/designLimit';
import { hasAccessToDocument } from '../../../../utils/user';
import { EVENT_CREATE_NEW_PROTOTYPE } from '../../../../constants/desktopAppEvents.constants';

export default class InsideProjectActionsList extends InsideProjectActions {
  constructor(props) {
    super(props);

    this.state = {
      isPlanInfoHidden: LocalStorage.load(localStorage.IS_IMPORT_UPLOAD_INFO_HIDDEN) || false,
    };
  }

  componentDidMount() {
    const { documentsCount } = this.props;
    if (isDesktopApp && documentsCount > 0) {
      window.addEventListener(EVENT_CREATE_NEW_PROTOTYPE, this.createDocument);
    }
  }

  componentWillUnmount() {
    const { documentsCount } = this.props;
    if (isDesktopApp && documentsCount > 0) {
      window.removeEventListener(EVENT_CREATE_NEW_PROTOTYPE, this.createDocument);
    }
  }

  renderDocumentsOption() {
    const { isMergeLimitedLibrariesEnabled, totalDocumentsCount: designCount, openModalboxAddMergeLibrary, isMergeDisabled, showAddPrototypesLimitsModal } = this.props;
    const limited = designCount && isDesignLimitReached(designCount.in_plan, designCount.used);

    if (!hasAccessToDocument(this.props)) { return null; }

    return (
      <li className="with-cloud">
        <ActionDocuments
          createUXDocument={this.createUXDocument}
          openBrowserFilePicker={this.props.openBrowserFilePicker}
          isDocumentUploadEnabled={this.props.isDocumentUploadEnabled}
          isLimitReached={limited}
          isMergeLimitedLibrariesEnabled={isMergeLimitedLibrariesEnabled}
          isMergeDisabled={isMergeDisabled}
          openModalboxAddMergeLibrary={openModalboxAddMergeLibrary}
          createMergeDocument={this.createMergeDocument}
          isUXDocumentsEnabled={this.props.isUXDocumentsEnabled}
          showAddPrototypesLimitsModal={showAddPrototypesLimitsModal}
        />
      </li>
    );
  }

  renderCreatePrototypeOption() {
    const { showAddPrototypesLimitsModal, totalDocumentsCount: docsCount } = this.props;
    const { isWorking } = this.state;
    if (!hasAccessToDocument(this.props)) { return null; }

    const limitReached = docsCount && isDesignLimitReached(docsCount.in_plan, docsCount.used);
    const buttonAction = limitReached ? showAddPrototypesLimitsModal : this.createDocument;

    return (
      <li>
        <Button
          href="#create-prototype"
          iconName="plus"
          isWorking={isWorking}
          onClick={buttonAction}>
          Create another prototype
        </Button>
      </li>
    );
  }

  @autobind
  hidePlanInfo(e) {
    e.preventDefault();
    this.setState({
      isPlanInfoHidden: true,
    });

    LocalStorage.save(localStorage.IS_IMPORT_UPLOAD_INFO_HIDDEN, true);
  }

  @autobind
  createDocument(e) {
    super.createDocument(e);
  }

  @autobind
  createMergeDocument(e, openLibraries = false) {
    const { createDocumentRaw, project } = this.props;

    this.setState({
      isWorking: true && !openLibraries,
      isWorkingMerge: !!openLibraries,
    });

    createDocumentRaw(project.idProject, null, openLibraries);
  }

  render() {
    const listClassNames = classnames('inside-project-actions', GLOBAL_ELEMENTS.UNORDERED_LIST);

    return (
      <ul className={listClassNames}>
        {this.renderCreatePrototypeOption()}
        <li className="with-vertical-line"><span>or</span></li>
        {this.renderDocumentsOption()}
      </ul>
    );
  }
}
