import { connect } from 'react-redux';
import * as documents from '../../controllers/actions/document.actions';
import * as modal from '../../controllers/actions/modal.actions';
import { modalsIds } from '../../constants/modal.constants';
import { sortItems } from '../../utils/projectsSorting';
import { setDocumentWorkingState } from '../../controllers/actions/insideProject.actions';
import { setProjectListLoadCompleted } from '../../controllers/actions/settings.actions';
import InsideProjectList from '../components/InsideProjectList/InsideProjectList';
import { isSketchDirectUploadAllowed } from '../../utils/uploadFiles';

export const mapStateToProps = (state) => {
  const { currentDocumentsActions } = state.documentActions;
  const {
    // eslint-disable-next-line no-shadow
    projectsList, project, documents, workingDocumentId,
  } = state.insideProject;
  const { features: { sketch_direct_upload: sketchDirectUpload } } = state.features;
  const { documentSortingType } = state.settings.data;
  const isSketchUploadAllowed = isSketchDirectUploadAllowed(sketchDirectUpload);
  const { favsPrototypes, favsFilters } = state.settings.data;
  const { favsPrototypes: filterType } = favsFilters;

  return {
    currentDocumentsActions,
    documents: sortItems(documents.slice(0), documentSortingType),
    isSketchUploadAllowed,
    projectsList,
    project,
    workingDocumentId,
    favsPrototypes,
    filterType,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  openModalboxShare: (document) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_SHARE, document));
  },

  openModalboxDocumentChangeName: (document) => {
    dispatch(modal.openModal(modalsIds.MODALBOX_DOCUMENT_CHANGE_NAME, document));
  },

  removeDocumentPermanently: (idDocument) => {
    dispatch(documents.removeDocumentPermanently(idDocument));
  },

  startChromeExtensionUpload: (fileId) => {
    dispatch(documents.startChromeExtensionUpload(fileId));
  },

  updateDocumentAfterChromeExtensionUpload: (fileId, newName, data) => {
    dispatch(documents.updateDocumentAfterChromeExtensionUpload(fileId, newName, data));
  },

  moveDocument: (idDocument, idProject) => {
    dispatch(documents.moveDocument(idDocument, idProject));
  },

  renameDocument: (idDocument, name) => {
    dispatch(documents.renameDocument(idDocument, name));
  },

  createDocumentByChromeExtension: (fileId, name, createdAt, port) => {
    dispatch(documents.createDocumentByChromeExtension(fileId, name, new Date(createdAt).toISOString(), port));
  },

  removeDocument: (idDocument) => {
    dispatch(documents.removeDocument(idDocument));
  },

  checkConversionStatus: (documentsIds) => {
    dispatch(documents.checkConversionStatus(documentsIds));
  },

  forceGenerateDocumentCovers: (data) => {
    dispatch(documents.forceGenerateDocumentCovers(data));
  },

  setDocumentWorkingState: (idDocument) => {
    dispatch(setDocumentWorkingState(idDocument));
  },
  setProjectListLoadCompleted: () => {
    dispatch(setProjectListLoadCompleted());
  },
});

export const DocumentsList = connect(mapStateToProps, mapDispatchToProps)(InsideProjectList);
