/* eslint-disable max-len,react/button-has-type */

'use strict';

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, StringUtils } from '@uxpin/shared-components';
import { APPEARANCES, Button, Link } from '@uxpin/design-system';
import _ from 'lodash';
import ModalboxProjectChangeName from './ModalboxProjectChangeName';
import * as localStorage from '../../../utils/localStorage';
import {isDesignLimitReached} from "../../../utils/designLimit";
import keys from "../../../constants/keys.constants";

export default class ModalboxDocumentChangeName extends ModalboxProjectChangeName {
  constructor(props) {
    super(props);
    this.MODAL = {
      WRAPPER_ID: 'project-change-name-modal-box',
      CLASS_NAMES: ['modal-box-medium-width'],
      TITLE: 'Change Prototype Name',
    };

    this.MODAL_CREATE_PROTOTYPE = { ...this.MODAL, TITLE: 'Create New Prototype' };
    this.state = {
      value: '',
    };
  }

  isNewPrototype() {
    return _.isEmpty(this.props.data);
  }

  save() {
    const {
      renameDocument, updateDocumentName, onClose, data,
    } = this.props;
    const valueTrimmed = StringUtils.reduceWhiteSpaces(this.state.value).trim();

    if (data.isChromeExtensionUpload) {
      const obj = localStorage.load('files') || {};
      obj[data.fileId] = valueTrimmed;
      localStorage.save('files', obj);
      updateDocumentName(data.idDocument, valueTrimmed);
    } else {
      renameDocument(data.idDocument, valueTrimmed);
    }

    onClose();
    this.isChanged = false;
  }

  create() {
    const {
      createWithRenameDocument, idProject, onClose,
      showAddPrototypesLimitsModal,
      totalDocumentsCount: docsCount
    } = this.props;
    const valueTrimmed = StringUtils.reduceWhiteSpaces(this.state.value).trim();
    const limitReached = docsCount && isDesignLimitReached(docsCount.in_plan, docsCount.used);
    if (limitReached) {
      showAddPrototypesLimitsModal();
      onClose();
      return;
    }

    this.setState({
      isCreating: true,
    })

    createWithRenameDocument(idProject, valueTrimmed,() => {
      this.setState({
        isCreating: false,
      })
      onClose();
    });
    this.isChanged = false;
  }

  onKeyDown(e) {
    if (e.keyCode === keys.ENTER && !this.isEmpty()) {
      if (this.isNewPrototype()) {
        this.create();
      } else {
       this.save();
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const propsNames = Object.keys(ModalboxDocumentChangeName.propTypes);
    return propsNames.some((propsName) => !_.isEqual(this.props[propsName], nextProps[propsName]))
      || !_.isEqual(this.state, nextState);
  }

  render() {
    return (
      <Modal
        wrapperId={this.MODAL.WRAPPER_ID}
        classNames={this.MODAL.CLASS_NAMES}
        title={this.isNewPrototype() ? this.MODAL_CREATE_PROTOTYPE.TITLE : this.MODAL.TITLE}
        isOpen={this.props.isOpen}
        onOpen={this.onOpen}
        onClose={this.onClose}>
        <section className="modal-box-content">
          <fieldset>
            <label htmlFor="project-name" className="block-label">Prototype Name</label>
            <input ref="input" id="project-name" type="text" onKeyDown={this.onKeyDown.bind(this)} value={this.state.value} onChange={this.handleChange.bind(this)} />
          </fieldset>
        </section>
        <footer>
          <ul className="btns-group">
            <li>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <Link appearance={APPEARANCES.NEUTRAL} href="#cancel" onClick={this.onClose.bind(this)}>Cancel</Link>
            </li>
            <li>
              {/* eslint-disable-next-line react/jsx-no-bind */}
              <Button isWorking={this.state.isCreating} onClick={this.isNewPrototype() ?  this.create.bind(this) : this.save.bind(this)} isDisabled={this.isEmpty()}>{this.isNewPrototype() ? 'Create' : 'Save'}</Button>
            </li>
          </ul>
        </footer>
      </Modal>
    );
  }
}

ModalboxDocumentChangeName.defaultProps = {
  data: {},
  isOpen: false,
  renameDocument: _.noop,
  onClose: _.noop,
};

ModalboxDocumentChangeName.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  renameDocument: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
