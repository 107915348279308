'use strict';

export const featuresActions = {
  FETCH_FEATURES: 'FETCH_FEATURES',
};

export const FEATURES = {
  APPROVAL_PROCESS: 'approval_process',
  CREATE_OWN_ACCOUNT: 'create_own_account',
  CUSTOM_FONTS_ADDON: 'custom_fonts_addon_option',
  DESIGN_SYSTEMS: 'design_systems',
  DESIGN_SYSTEMS_SAMPLES: 'design_systems_samples',
  DRIFT_SUPPORT: 'drift_support',
  FAVS_SYSTEM: 'favs_system',
  FREE_FLOW_DOCS_ADDON: 'free_flow_docs_addon_option',
  ITERATIONS: 'iterations',
  PROJECT_GROUPS: 'dashboard_groups',
  PROJECT_STATUS: 'project_status',
  REFRESHED_LAYOUT: 'refreshed_layout',
  SPEC_MODE_ADDON: 'spec_mode_addon_option',
  TRACK_KM: 'track_km',
  MERGE: 'merge',
  MERGE_LIMITED_LIBRARIES: 'merge_limited_libraries',
  PROTOTYPE_NAME: 'prototype_name',
  MERGE_READ_ONLY: 'merge_read_only',
  ENFORCE_NAMES_OPTION: 'enforce_names_option',
  STORYBOOK_LIBRARIES: 'storybook_libraries',
};

export const FEATURE_STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  TEASER: 'teaser',
};
